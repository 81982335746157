import React, { useState } from 'react';
import { Box, Typography, TextField, Button, Alert, Grid } from '@mui/material';
import axios from 'axios';

function Messages({ channelId, token }) {
  const [shortText, setShortText] = useState('');
  const [longText, setLongText] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');

  const apiUrl = process.env.REACT_APP_API_URL;

  const handleSubmit = (event) => {
    event.preventDefault();
    setErrorMessage('');
    setSuccessMessage('');

    let content = longText;

    if (shortText) {
      content = `${longText}<br><br>~ ${shortText}`;
    }

    const formData = {
      channelId,
      token,
      title: 'Simple Message',
      content: content,
      attribution: 1
    };

    axios
      .post(`${apiUrl}/api/message/submit`, formData)
      .then((response) => {
        if (response.data.success) {
          setSuccessMessage('Your message has been successfully submitted!');
          setShortText('');
          setLongText('');
        } else {
          setErrorMessage(response.data.error || 'There was an issue with your submission. Please try again.');
        }
      })
      .catch((error) => {
        const errorMsg = error.response?.data?.error || 'An unexpected error occurred.';
        setErrorMessage(errorMsg);
      });
  };

  return (
    <Box sx={{ width: '100%', maxWidth: 600, margin: '0 auto', padding: 3, textAlign: 'center' }}>

      {errorMessage && <Alert severity="error" sx={{ mb: 2 }}>{errorMessage}</Alert>}
      {successMessage && <Alert severity="success" sx={{ mb: 2 }}>{successMessage}</Alert>}

      <form onSubmit={handleSubmit}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TextField
              label="Your Name"
              value={shortText}
              onChange={(e) => setShortText(e.target.value)}
              variant="outlined"
              fullWidth
              required
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              label="Message (Inspiring or Motivational Content)"
              value={longText}
              onChange={(e) => setLongText(e.target.value)}
              variant="outlined"
              fullWidth
              multiline
              rows={4}
              required
              helperText="Use plain text or emojis only. No links or special characters."
            />
          </Grid>
          <Grid item xs={12}>
            <Button type="submit" variant="contained" color="primary" fullWidth>
              Submit
            </Button>
          </Grid>
        </Grid>
      </form>
    </Box>
  );
}

export default Messages;
