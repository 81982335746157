import React, { useState, useEffect } from 'react';
import {
  Box,
  Typography,
  TextField,
  Alert,
  Paper,
  Tabs,
  Tab,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import YouTube from './YouTube';
import Messages from './Messages';
import Spotify from './Spotify';
import RichTextMessages from './RichTextMessages';

function UnifiedForm({ initialChannelId, initialToken }) {
  const [channelId, setChannelId] = useState(initialChannelId || '');
  const [token, setToken] = useState(initialToken || '');
  const [currentTab, setCurrentTab] = useState(0);
  const [expanded, setExpanded] = useState(!initialChannelId || !initialToken); // Expanded if either field is empty

  const isPremium = new URLSearchParams(window.location.search).get('premium') === 'true';

  const handleTabChange = (event, newValue) => {
    setCurrentTab(newValue);
  };

  const handleAccordionToggle = () => {
    setExpanded((prev) => !prev);
  };

  useEffect(() => {
    // If either channelId or token is empty, set the accordion to expanded
    setExpanded(!initialChannelId || !initialToken);
  }, [initialChannelId, initialToken]);

  return (
    <Box sx={{ width: '100%', maxWidth: 700, mx: 'auto', mt: 4 }}>
      {/* Header and Information Section */}
      <Typography variant="h4" align="center">Link-A-Charm™ Private Channels</Typography>
      <Typography variant="body1" sx={{ textAlign: 'center', mt: 2, mb: 2 }}>
        Link-A-Charm is a service that provides dynamic content on demand. Use this form to create and upload the personalized content for your private Link-A-Charm channel.
      </Typography>

      <Alert severity="info" sx={{ mt: 2, mb: 2 }}>
        <Typography variant="body2">
          Did you know? You can subscribe any of our Link-A-Charm products to this channel. Simply select the tab labelled private at the onboarding screen, and use this channel ID and token.
        </Typography>
      </Alert>

      {/* Collapsible Channel Details Section */}
      <Accordion expanded={expanded} onChange={handleAccordionToggle}>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography variant="subtitle1">Channel Details</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <TextField
            label="Channel ID"
            value={channelId}
            onChange={(e) => setChannelId(e.target.value)}
            variant="outlined"
            fullWidth
            required
            sx={{ mb: 2 }}
            helperText="Enter the Channel ID of your Link-A-Charm private channel."
          />
          <TextField
            label="Channel Code"
            value={token}
            onChange={(e) => setToken(e.target.value)}
            variant="outlined"
            fullWidth
            required
            helperText="Your channel code (required to submit content)."
          />
        </AccordionDetails>
      </Accordion>

      {/* Tab Navigation for Content Forms */}
      <Paper elevation={3} sx={{ p: 2, mt: 3 }}>
        <Typography variant="h6" sx={{ textAlign: 'center', mb: 1 }}>
          Create Content for Your Channel
        </Typography>
        <Typography variant="body2" sx={{ textAlign: 'center', color: 'gray', mb: 2 }}>
          You can add as many items as you need, but edit & delete functionality is not yet implemented.
        </Typography>

        <Tabs value={currentTab} onChange={handleTabChange} centered>
          <Tab label="Text Message" />
          {isPremium && <Tab label="Rich Content" />}
          <Tab label="YouTube Video" />
        </Tabs>

        {/* Render the selected form based on tab selection */}
        {currentTab === 0 && <Messages channelId={channelId} token={token} />}
        {isPremium && currentTab === 1 && <RichTextMessages channelId={channelId} token={token} />}
        {currentTab === (isPremium ? 2 : 1) && <YouTube channelId={channelId} token={token} />}
      </Paper>
    </Box>
  );
}

export default UnifiedForm;
