import React, { useState } from 'react';
import { Box, Button, Alert, Grid } from '@mui/material';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import axios from 'axios';

function RichTextMessages({ channelId, token }) {
  const [content, setContent] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');

  const apiUrl = process.env.REACT_APP_API_URL;

  const editorConfig = {
    toolbar: [
      'heading',
      '|',
      'bold',
      'italic',
      'link',
      'bulletedList',
      'numberedList',
      '|',
      'blockQuote',
      'undo',
      'redo'
    ],
    htmlSupport: {
      allow: [
        {
          name: /^(h[1-6]|p|li|ul|ol|blockquote|strong|em|a|br)$/,
          attributes: {
            href: true,
            target: true
          },
          classes: false,
          styles: false
        }
      ]
    }
  };

  const cleanHtml = (html) => {
    return html
      .replace(/&nbsp;/g, ' ')
      .replace(/<br\s*\/?>\s*<br\s*\/?>/g, '<br>')
      .replace(/<p>\s*<\/p>/g, '')
      .trim();
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    setErrorMessage('');
    setSuccessMessage('');

    if (!content.trim()) {
      setErrorMessage('Content cannot be empty.');
      return;
    }

    // Format data according to the API's expected structure
    const formData = {
      channelId,
      token,
      title: 'Rich Content',
      content: cleanHtml(content),
      attribution: false
    };

    // Update the endpoint to match your API route
    axios
      .post(`${apiUrl}/api/message/submit`, formData)
      .then((response) => {
        if (response.data.success) {
          setSuccessMessage('Your content has been successfully submitted!');
          setContent('');
        } else {
          setErrorMessage(response.data.error || 'There was an issue with your submission. Please try again.');
        }
      })
      .catch((error) => {
        const errorMsg = error.response?.data?.error || 'An unexpected error occurred.';
        setErrorMessage(errorMsg);
      });
  };

  return (
    <Box sx={{ width: '100%', maxWidth: 800, margin: '0 auto', padding: 3 }}>
      {errorMessage && <Alert severity="error" sx={{ mb: 2 }}>{errorMessage}</Alert>}
      {successMessage && <Alert severity="success" sx={{ mb: 2 }}>{successMessage}</Alert>}

      <form onSubmit={handleSubmit}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Box
              sx={{
                border: '1px solid rgba(0, 0, 0, 0.23)',
                borderRadius: 1,
                '.ck-editor__editable': {
                  minHeight: '300px'
                }
              }}
            >
              <CKEditor
                editor={ClassicEditor}
                config={editorConfig}
                data={content}
                onChange={(event, editor) => {
                  const data = editor.getData();
                  setContent(data);
                }}
              />
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Button type="submit" variant="contained" color="primary" fullWidth>
              Submit
            </Button>
          </Grid>
        </Grid>
      </form>
    </Box>
  );
}

export default RichTextMessages;
