import React, { useState } from 'react';
import { Alert, TextField, Button, Grid, Box } from '@mui/material';
import axios from 'axios';

function MixtapeForm({ channelId, token }) {
  const [url, setUrl] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');

  const apiUrl = process.env.REACT_APP_API_URL;

  const handleSubmit = (event) => {
    event.preventDefault();
    setErrorMessage('');
    setSuccessMessage('');

    const formData = {
      channelId,
      token,
      title: 'YouTube Video',
      content: url,
    };

    axios
      .post(`${apiUrl}/api/youtube/submit`, formData)
      .then((response) => {
        if (response.data.success) {
          setSuccessMessage('Your link has been successfully submitted!');
          setUrl('');
        } else {
          setErrorMessage(response.data.error || 'There was an issue with your submission. Please try again.');
        }
      })
      .catch((error) => {
        const errorMsg = error.response?.data?.error || 'An unexpected error occurred.';
        setErrorMessage(errorMsg);
      });
  };

  return (
    <Box sx={{ width: '100%', maxWidth: 600, margin: '0 auto', padding: 3, textAlign: 'center' }}>
      {errorMessage && <Alert severity="error" sx={{ mb: 2 }}>{errorMessage}</Alert>}
      {successMessage && <Alert severity="success" sx={{ mb: 2 }}>{successMessage}</Alert>}

      <form onSubmit={handleSubmit}>
        <Grid container spacing={2}>

          {/* Single YouTube Link Field */}
          <Grid item xs={12}>
            <TextField
              label="YouTube Link"
              value={url}
              onChange={(e) => setUrl(e.target.value)}
              variant="outlined"
              helperText="The full YouTube URL of the video."
              fullWidth
              required
            />
          </Grid>

          {/* Submit Button */}
          <Grid item xs={12}>
            <Button type="submit" variant="contained" color="primary" fullWidth>
              Submit
            </Button>
          </Grid>
        </Grid>
      </form>
    </Box>
  );
}

export default MixtapeForm;
