import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import UnifiedForm from './components/UnifiedForm';

function App() {
  // Use URLSearchParams to get the parameters
  const queryParams = new URLSearchParams(window.location.search);
  const channelId = queryParams.get('id') || '';
  const token = queryParams.get('token') || '';

  return (
    <Router>
      <Routes>
        <Route path="/" element={<UnifiedForm initialChannelId={channelId} initialToken={token} />} />
      </Routes>
    </Router>
  );
}

export default App;
